/* styles.module.scss */

.root-container {
  display: flex;
}

.container {
  margin-top: 50px;
  text-align: center;
}

.textarea {
  width: 100%;
  min-height: 400px;
  box-sizing: border-box;
}

.stickyMenuBackground {
  top: 0;
  width: 100%;
  position: fixed;
}

.menuButton {
  margin-right: 10px;
  position: sticky !important;
  top: 10px;
  margin-left: 10px !important;
  z-index: 1000;
}

.drawer {
  width: 240px;
}

.spinnerWrapper {
  margin-top: 20px;
}

.dropdown {
  width: 100%;
  margin-top: 10px;
}

.dropToTeam {
  background-color: #f0f0f0;
  box-shadow: 4px 4px 4px rgba(60, 60, 93, 0.33) !important;
  transition: transform 0.5s;
  transform: scale(1.03) perspective(0px);  
  th > span {
    display: block;
  }
}

.trashCanWrapper {
  display: flex;
  justify-content: center;
}

.trashHover {
  box-shadow: 4px 4px 4px rgba(60, 60, 93, 0.33);
  background-color: #f0f0f0;
}

.trashCan {
  display: block;
  width: 50%;
  border: 1px solid gray;
  padding: 5px;
  opacity: 0;
}

.isDraggingPlayer {
  transition: opacity 0.8s;  
  opacity: 1;
}

.checkbox {
  margin-top: 10px;
}

.headerWithIcon {
  display: flex;
  justify-content: center;
}

.tableContainer {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.table {
  margin-bottom: 20px;
  border: 1px solid gray;
  width: 250px;
}

.tableHeaderCell {
  background-color: #f2f2f2;
  text-align: center !important;
  padding: 10px !important;
  font-weight: bold !important;
}

.selectedRow {
  background-color: blue;
}

.tableHeaderColor-blue {
  // background-color: blue;
}

.tableHeaderColor-red {
  // background-color: red;
}

.tableHeaderColor-white {
  // background-color: white;
}

.tableHeaderColor-black {
  // background-color: black;
}

.tableCell {
  padding: 10px !important;
}

.tableCellDrag {
  padding: 10px !important;
  cursor: move;
}

.error {
  color: red;
  
  ul {
    text-align: left;
  }
}

.modalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  border: 2px solid #000;
  box-shadow: 24px;
}

/* Stiler spesifikke for skjermstørrelser mindre enn 600 piksler (mobil) */
@media (max-width: 600px) {
  .stickyMenuBackgroundVisible {
    z-index: 1000;
    transition: 0.3s;
    background-color: #fff;
    opacity: 0.95;
    border-bottom: 1px solid lightgray;
  }

  .headerWithIcon {
    align-items: flex-end;
  }

  .headerWithIcon h2 {
    margin-bottom: 5px;
  }

  .tableHeaderCell {
    padding: 3px !important;
  }

  .tableContainer {
    margin-top: 5px;
  }
  
  .tableCell {
    padding: 5px 3px !important;
  }

  .table {
    width: 170px;
  }
}
